.colorTalk{
    color:#ffd54f;
}

.marginContact{
    margin:0px;
}

.BackGroundContact{
    background-color: #ffd54f;
    color:rgb(21,46,63);
    width: 100%;
}
.ColorIconContact{
    color:rgb(21,46,63);
}

.OffDecotaror{
    text-decoration: none;
}